
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { CustomerService } from '@/services/customer-service';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { AddressService } from '@/services/address-service';

@Component
export default class extends Vue {
  $refs!: {
    RollDirectionAlertForm: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  @Prop()
  address!: any;
  @Prop()
  defaultRollDirection!: number;
  @Prop()
  message!: string;
  @Prop()
  shouldShowAlert!: boolean;
  /* Watchers */
  /* Data */
  isSelectionValid: boolean = false;
  showAlert: boolean = false;
  selectedRollDirection: number | null = null;
  rollDirectionOptions = [
    {
      title: '#4 unwind',
      description: 'Left side of label dispenses first. Wound Outside',
      src: require('@/assets/4unwind.svg'),
      value: 4
    },
    {
      title: '#3 unwind',
      description: 'Right side of label dispenses first. Wound Outside',
      src: require('@/assets/3unwind.svg'),
      value: 3
    },
    {
      title: 'Click here to update unwind direction',
      description: 'This only needs to be updated once. #4 is most common',
      src: 'mdi-progress-question',
      value: 10
    }
  ];
  isUpdatingRollDirection: boolean = false;
  valid: boolean = true;
  lazy: boolean = true;
  /* Methods */
  async updateRollDirectionForAddressOption() {
    this.isUpdatingRollDirection = true;
    try {
      const response = await AddressService.UpdateRollDirection(
        this.address.Id,
        this.selectedRollDirection!
      );
    } catch (err) {
      console.log(err);
    }
    this.isUpdatingRollDirection = false;
  }
  async updateCustomerOptionRollDirectionForDefault() {
    this.isUpdatingRollDirection = true;
    try {
      const response = await CustomerService.UpdateCustomerOptionRollDirection(
        this.selectedRollDirection!
      );
    } catch (err) {
      console.log(err);
    }
    this.isUpdatingRollDirection = false;
  }
  async updateRollDirection() {
    try {
      this.address.CustomerPickup
        ? await this.updateCustomerOptionRollDirectionForDefault()
        : await this.updateRollDirectionForAddressOption();
      this.$emit('onUpdateRollDirectionId', this.selectedRollDirection);
      this.$emit('closeAlert');
    } catch (err) {
      console.log(err);
      SetSnackBar(
        'Something went wrong changing your roll direction, please refresh your page and try again, or contact techincal support if error persists'
      );
    }
  }
  onRollSelectionChange() {
    this.$refs.RollDirectionAlertForm.resetValidation();
  }
  onConfirmClick() {
    // put api for roll direction here
    const valid = this.$refs.RollDirectionAlertForm.validate();
    if (valid) {
      this.updateRollDirection();
    }
  }
  /* Loaders */
  /* Mounted */
  mounted() {
    this.selectedRollDirection = this.defaultRollDirection;
    this.showAlert = this.shouldShowAlert;
  }
  /* Created */
}
