
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { Mutation, Getter } from 'vuex-class';
import { AddressService } from '@/services/address-service';
import { OrdersService } from '@/services/orders-service';
import { Address, AddressChallengeResponse } from '@/store/models/AddressModel';
import { OrderlineDetails } from '@/store/models/OrderlineModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import RollDirectionAlert from '@/components/RollDirectonAlert.vue';
import { ItemState } from '@/store/models/ItemModel';
import { ConstValues } from '@/ConstValues';
import { DisableFieldAutofill } from '@/helpers/WebHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
@Component({
  components: {
    RollDirectionAlert
  }
})
export default class extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
  };
  /* Properties */
  @Prop() originalAddress!: Address;
  @Prop() customUpdate!: any;
  @Prop({ default: true }) shouldGetContactInfo!: boolean;
  @Prop({ default: true }) shouldUpdateOrderlines!: boolean;
  @Prop({ default: false }) isProofAddress!: boolean;
  /* Store Actions */
  @Mutation('setCurrentOrder', { namespace: 'orderlines' })
  setCurrentOrderInStore: any;
  @Getter('getUserValidation', { namespace: 'profile' })
  userValidation!: any;
  @Getter('displayName', { namespace: 'profile' })
  getDisplayName!: any;
  /* Watchers */
  @Watch('addressToEdit.Country.Id')
  onCountryIdChange() {
    if (
      this.addressToEdit.Country.Id > ConstValues.DEFAULT_COUNTRY_ID &&
      this.addressToEdit.State != ''
    ) {
      this.addressToEdit.State = '';
    } else {
      this.addressToEdit.Province = '';
    }
  }
  @Watch('selectedRollDirectionId')
  async onRollDirectionChange(val: any) {
    if (
      val !== this.addressToEdit.Options.RollDirectionId &&
      this.originalAddress &&
      this.originalAddress.Options &&
      !!this.originalAddress.Options.Id
    ) {
      await this.getOrderlinesWithSameShippingAddress(this.addressToEdit.Id);
    } else {
      this.orderlineWithSameAddress = [];
    }
  }
  @Watch('addressToEdit', { deep: true })
  onAddressToEditChange(val: Address, oldVal: any) {
    if (
      oldVal.Status == ItemState.New ||
      (oldVal.Status === ItemState.Edit && oldVal.Id && this.hasLoadedAddress)
    ) {
      this.addressToEdit.HasChanged = true;
    }
  }
  /* Data */
  shouldShowAlert: boolean = false;
  hasLoadedAddress: boolean = false;
  addressToEdit: Address = new Address();
  selectedRollDirectionId: number | null = null;
  shouldOpenDialog: boolean = false;
  isFormValid: boolean = false;
  isSavingAddress: boolean = false;
  isOrderlineChangeAlertClicked: boolean = false;
  orderlineWithSameAddress: OrderlineDetails[] = [];
  showAddressRollDirectionChangeAlert: boolean = false;
  isOrderlineLoading: boolean = false;
  shouldShowAddressNote: boolean = false;
  additionalAlert: string = '';
  rollDirectionOptions: any = [
    {
      title: '#4 unwind',
      description: 'Left side of label dispenses first. Wound Outside',
      src: require('@/assets/4unwind.svg'),
      value: 4
    },
    {
      title: '#3 unwind',
      description: 'Right side of label dispenses first. Wound Outside',
      src: require('@/assets/3unwind.svg'),
      value: 3
    }
  ];
  states: { id: string; name: string }[] = [];
  countries: any[] = [];

  isAdmin: boolean = false;
  /* Utility Functions */
  async openAddressDialog(
    shouldClearData: boolean = false,
    additionalAlert: string = ''
  ) {
    await this.GetStates();
    await this.GetCountries();
    this.additionalAlert = additionalAlert;
    setTimeout(() => {
      if (shouldClearData || !this.originalAddress) {
        this.addressToEdit = new Address();
        this.addressToEdit.Country = this.countries[0];
      } else {
        Object.assign(this.addressToEdit, this.originalAddress);
        this.hasLoadedAddress = true;
      }
      this.setSelectedRollDirection();
      this.shouldShowAlert = this.addressToEdit.Options.RollDirectionId === 10;
      this.isUserAdmin();
      this.shouldOpenDialog = true;
    }, 50);
    setTimeout(() => {
      DisableFieldAutofill('state');
    }, 500);
  }
  async isUserAdmin() {
    this.isAdmin = this.userValidation.IsAdmin;
  }
  setSelectedRollDirection() {
    this.selectedRollDirectionId = this.addressToEdit.Options.RollDirectionId;
    if (!this.selectedRollDirectionId)
      this.selectedRollDirectionId = this.rollDirectionOptions[0].value;
  }

  async updateAddress() {
    this.isSavingAddress = true;
    this.addressToEdit.Options.RollDirectionId = this.selectedRollDirectionId!;
    let model = ConversionHelper.convertAddressToAddressModel(
      this.addressToEdit,
      this.originalAddress && this.originalAddress.Id ? false : true,
      this.addressToEdit.IsBilling,
      false,
      this.isProofAddress
    );
    if (this.customUpdate) {
      this.$emit('customUpdate', model);
      this.isSavingAddress = false;
      this.shouldOpenDialog = false;
      return;
    }

    if (this.originalAddress && this.originalAddress.Id) {
      try {
        const { data } = await AddressService.UpdateAddress(model);
        let addressResponse = new AddressChallengeResponse(data);
        if (addressResponse.HasValidationErrors) {
          SetSnackBar(
            `Failed to add new address, reason: ${addressResponse.ValidationErrors[0]}`
          );
          this.isSavingAddress = false;
        } else {
          if (this.shouldUpdateOrderlines) {
            await this.updateOrderline(this.selectedRollDirectionId!);
            this.orderlineWithSameAddress = [];
          }
          this.showAddressRollDirectionChangeAlert = false;
          this.isOrderlineChangeAlertClicked = false;
          this.$emit('addressUpdated', addressResponse.EnhancedAddress);
          SetSnackBar('Address updated successfully');
          this.isSavingAddress = false;
          this.shouldOpenDialog = false;
        }
      } catch (err) {
        SetSnackBar(`Failed to new address, ${err}`);
        this.isSavingAddress = false;
      }
    } else {
      try {
        const response = await AddressService.AddNewShippingAddress(model);
        let addressResponse = new AddressChallengeResponse(response.data);
        if (addressResponse.HasValidationErrors) {
          SetSnackBar(
            `Failed to add new address, reason: ${addressResponse.ValidationErrors[0]}`
          );
          this.isSavingAddress = false;
        } else {
          this.$emit('addressUpdated', response.data.EnhancedAddress);
          SetSnackBar('Address added successfully');
          this.isSavingAddress = false;
          this.shouldOpenDialog = false;
        }
      } catch (error) {
        SetSnackBar(`Failed to new address, ${error}`);
        this.isSavingAddress = false;
      }
    }
  }
  async getOrderlinesWithSameShippingAddress(addressId: string) {
    if (!addressId) return;
    this.isOrderlineLoading = true;
    const { data: orderlines } = await OrdersService.GetAllOrderlineByShippingIdAndStatus(
      addressId
    );
    if (orderlines && orderlines[0]) {
      this.orderlineWithSameAddress = orderlines.map(
        (ol: any) => new OrderlineDetails(ol)
      );
      this.showAddressRollDirectionChangeAlert =
        !!this.orderlineWithSameAddress && this.orderlineWithSameAddress.length > 0;
    }
    this.isOrderlineLoading = false;
  }
  async updateOrderline(rollDirectionId: number) {
    try {
      if (this.orderlineWithSameAddress[0]) {
        const orderlines = this.orderlineWithSameAddress.map(ol => {
          ol.RollDirection.Id = rollDirectionId;
          return ConversionHelper.convertOrderlineToAddModel(ol);
        });
        const model = {
          Orderlines: orderlines
        };
        await OrdersService.SaveOrderlines(model);
      }
    } catch (err) {
      console.log(err);
    }
  }
  async updateOrderlineCallback(orderlines: OrderlineDetails[]) {
    const loadedOrderlines = this.$store.getters['orderlines/orderlines'];
    for (let i = 0; i < orderlines.length; i++) {
      let oldIndex = loadedOrderlines.findIndex((ol: any) => ol.Id === orderlines[i].Id);
      if (oldIndex > -1) {
        loadedOrderlines[oldIndex] = new OrderlineDetails(orderlines[i]);
      }
    }
    this.setCurrentOrderInStore(loadedOrderlines);
  }
  closeAlert() {
    this.shouldShowAlert = false;
  }
  onUpdateRollDirectionId(rollDirectionId: number) {
    this.selectedRollDirectionId = rollDirectionId;
  }
  /* Loaders */
  async GetStates() {
    const response = await AddressService.GetStates();
    this.states = Object.keys(response.data).map(key => ({
      id: key,
      name: response.data[key]
    }));
  }
  async GetCountries() {
    const response = await AddressService.GetCountries();
    this.countries = response.data;
  }
  /* Computed */
  get ConstValues() {
    return ConstValues;
  }
  get shouldDisableSaveBtn() {
    return (
      this.showAddressRollDirectionChangeAlert && !this.isOrderlineChangeAlertClicked
    );
  }
  /* Mounted */
  /* Created */
}
